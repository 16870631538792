import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PostBox from "../components/post-box";
import {graphql} from "gatsby";

const TagPage = (info) => {
  const posts = info.data.allWpPost.nodes;
  const {name, slug} = info.pageContext;
  //const tagUrl = '/tag/' + slug;

  return (
    <Layout>
      <SEO title={'Latest posts from the ' + name + ' category'}
        description={`Listing of the latest articles, case studies, insights for category ${name}.`}
      />

      <section className="taxonomy-index">
        <div className="container-fluid">
          <div className="row columns-8">
            <h1 className="taxonomy-index__title">Category: {name} </h1>
          </div>
        </div>
      </section>

      <section className="article-index">
        <div className="container-fluid">

          <div className="row article-index__row">
            {posts.map(post => {
              return <PostBox post={post}/>
            })}
          </div>

          <br/>

          {/*<div className="row columns-4">
            PAGINATION!!!
          </div>*/}

        </div>
      </section>

    </Layout>
  )
}

export default TagPage

export const pageQuery = graphql`
    query WordPressPostsTags($slug: String) {
        allWpPost(
            filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
            sort: { fields: [date], order: DESC }
            limit: 8
        ) {
            nodes {
                excerpt
                uri
                date(formatString: "MMMM DD, YYYY")
                title
                excerpt

                post_acfs {
                    ctaLink
                    ctaText
                }

                author {
                    node {
                        firstName
                        lastName
                        slug
                    }
                }

                terms {
                    nodes {
                        name
                        slug
                    }
                }

                featuredImage {
                    node {
                        altText
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 650, maxHeight: 364, quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
